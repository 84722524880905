<template>
    <v-container fluid>
        <v-row>
            <v-col class="mb-0 pb-0 pt-0">
                <trev-job-reports-quick-links-bar></trev-job-reports-quick-links-bar>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <trev-jobs-table :isGlobal="true" :defaultInvoiceSentStatus="'0'" :defaultStatus="'Complete'"
                    :hideInvoiceStatus="true" :hideStatus="true" :hideSendToXero="false" :hideToggleInvoiceStatus="false">
                </trev-jobs-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TrevJobsTable from '../../../../components/jobs/trev-jobs-table.vue'
import TrevJobReportsQuickLinksBar from '../../../../components/reports/trev-job-reports-quick-links-bar.vue'
export default {
    components: {
        TrevJobsTable,
        TrevJobReportsQuickLinksBar
    }

}
</script>

<style>

</style>